import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import {
  LATCH_DIALOG_DATA,
  LatchAnalyticsService,
  LatchConfirmationDialogData,
  LatchDialogRef,
} from '@latch/latch-web';
import { User } from 'manager/models/user';
import { AuthService } from '@managerweb/services/auth/auth.service';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { environment } from 'environments/interface';
import { LatchStorageService } from 'manager/services/storage/storage.service';

export interface NotificationDialogBody {
  header: string,
  body: string,
  primaryButtonText: string,
  secondaryButtonText: string,
  cookieDuration: number | null,
  cookieName: string,
  trackingName: string,
  showCloseButton: boolean,
  user: User,
  notificationDateKey: string,
}

interface NotificationDialogData extends LatchConfirmationDialogData {
  user: User;
  notificationDateKey: string;
}

@Component({
  selector: 'latch-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDialogComponent {
  private currentUser: User;

  public get user(): User {
    return this.currentUser;
  }

  private rs = new RudderAnalytics();

  public get hasPrimaryButton(): boolean {
    return !!this.data.primaryButtonText;
  }

  public get hasSecondaryButton(): boolean {
    return !!this.data.secondaryButtonText;
  }

  constructor(
    @Inject(LATCH_DIALOG_DATA) public data: NotificationDialogData,
    private dialogRef: LatchDialogRef<'primary' | 'secondary' | 'close'>,
    private analyticsService: LatchAnalyticsService,
    private authService: AuthService,
    private storageService: LatchStorageService,
  ) {
    this.data.messages = this.data.messages.filter(message => !!message);
    if (this.data.closeTimeout) {
      setTimeout(() => this.close('close'), this.data.closeTimeout);
    }

    if (environment.rudderstack) {
      this.rs.load(environment.rudderstack.writeKey, environment.rudderstack.dataPlaneUrl, {
        queueOptions: {
          batch: {
            enabled: true,
            flushInterval: 1000,
          },
        }
      });
      if (this.authService) {
        authService.user$.subscribe(user => {
          if (user) {
            this.rs.identify(user.uuid);
          } else {
            this.rs.reset();
          }
        });
      }
    }
    this.currentUser = this.data.user;
  }

  public close(result: 'primary' | 'secondary' | 'close'): void {
    this.analyticsService.track(`Notification dialog: ${this.data.title ?? ''}`);
    this.rs.track(`Notification dialog: ${this.data.title ?? ''}`);
    this.dialogRef.close(result);

    /* Set the 'seen' behavior */
    // Get the key from the data
    const notificationDateKey = this.data.notificationDateKey;
    // Store today's date in YYYY-MM-DD format
    const today = new Date((this.data as any).user?.currentLoginDate || Date.now()).toISOString().split('T')[0];

    this.storageService.setLocalStorageItem(notificationDateKey, today);
  }

  public isTemplate(message: string | TemplateRef<unknown>): message is TemplateRef<unknown> {
    return message instanceof TemplateRef;
  }
}
