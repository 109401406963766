<latch-card>
 <ng-container latchCardHeader>
  <div class="latch-card-title" *ngIf="data.title">{{ data.title }}</div>
  <div class="latch-card-actions" *ngIf="data.showCloseButton">
   <button class="latch-button-icon" (click)="close('close')">
    <span class="latch-icon-close"></span>
   </button>
  </div>
 </ng-container>
  <ng-container latchCardContent>
    <ng-container *ngFor="let message of data.messages; first as isFirst">
      <div *ngIf="!isFirst" class="latch-divider"></div>
      <p latch-list-item-description class="text" *ngIf="!isTemplate(message)" [innerHTML]="message"></p>
      <div latch-list-item-description *ngIf="isTemplate(message)">
        <ng-container [ngTemplateOutlet]="message"></ng-container>
      </div>
    </ng-container>
  </ng-container>
  <ng-container latchCardFooter *ngIf="hasPrimaryButton || hasSecondaryButton">
   <button *ngIf="hasPrimaryButton"
           data-test-id="primary-button"
           class="latch-button"
           (click)="close('primary')">
    {{ data.primaryButtonText }}
   </button>
   <button *ngIf="hasSecondaryButton"
           data-test-id="secondary-button"
           class="latch-button-link"
           (click)="close('secondary')">
    {{ data.secondaryButtonText }}
   </button>
  </ng-container>
</latch-card>
