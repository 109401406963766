import { AccountPermissions } from './account';
import { BundleInfo } from './bundle';

export enum PmsSource {
  REALPAGE = 'REALPAGE',
  ENTRATA = 'ENTRATA',
  YARDI = 'YARDI',
  YARDI_API = 'YARDI_API',
  AVB = 'AVB',
  APPFOLIO = 'APPFOLIO',
}

export const PmsSourceDisplay: { [key in PmsSource]: string } = {
  [PmsSource.REALPAGE]: 'RealPage',
  [PmsSource.ENTRATA]: 'Entrata',
  [PmsSource.YARDI]: 'Yardi',
  [PmsSource.YARDI_API]: 'Yardi',
  [PmsSource.AVB]: 'AvalonBay',
  [PmsSource.APPFOLIO]: 'AppFolio',
};

export enum Role {
  PORTFOLIO_MANAGER = 'PORTFOLIO_MANAGER',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  GUEST = 'GUEST',
  RESIDENT = 'RESIDENT',
}

export interface Building {
  uuid: string;
  name: string;
  features?: BuildingFeature[];
  address?: Address;
  technician?: Technician;
  permissions?: AccountPermissions[];
  timezone?: string;
  pmsSource?: PmsSource;
  pmsUpdateTime?: Date;
  bundleInfo?: BundleInfo;
  buildingImageUrl?: string;
  role?: Role;
  accountUuid?: string;
}

export interface BuildingInput {
  name: string;
  tz: string;
  address: Address;
}

export interface Address {
  line1: string;
  line2?: string;
  line3?: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
}

export interface Technician {
  name: string;
  email?: string;
  phone?: string;
}

export enum BuildingFeature {
  DoorcodeSuppression = 'DOORCODE_SUPPRESSION',
  Intercom = 'INTERCOM',
  VirtualIntercom = 'VIRTUAL_INTERCOM',
  SmartHome = 'SMART_HOME',
  LeasePay = 'RENT_PAYMENTS', // This constant will be changed to LEASE_PAY once backend supports it
  RentPay = 'RENT_PAY',
  StripePayment = 'STRIPE_PAYMENT',
  VisualAccessLogs = 'VISUAL_ACCESS_LOGS',
  Commercial = 'COMMERCIAL',
  CommercialNotificationsSms = 'COMMERCIAL_NOTIFICATIONS_SMS',
  CommercialNotificationsVisitorVerified = 'COMMERCIAL_NOTIFICATIONS_VISITOR_VERIFIED',
  CommercialInviteRecurrence = 'COMMERCIAL_INVITE_RECURRENCE',
  CommercialInviteMultiDay = 'COMMERCIAL_INVITE_MULTI_DAY',
  CommercialInviteGroup = 'COMMERCIAL_INVITE_GROUP',
  CommercialInviteReplacePass = 'COMMERCIAL_INVITE_REPLACE_PASS',
  CommercialInviteListFilters = 'COMMERCIAL_INVITE_LIST_FILTERS',
  CommercialShowHost = 'COMMERCIAL_HOSTS',
  CommercialShowWatchlist = 'COMMERCIAL_WATCHLIST',
  SmartHomeNest = 'SMART_HOME_NEST',
  SmartHomeEnterpriseManagement = 'SMART_HOME_ENTERPRISE_MANAGEMENT',
  EnableAccessAutomation = 'ENABLE_ACCESS_AUTOMATION',
  Insights = 'PANEL_INSIGHTS',
  InsightsDashboard = 'INSIGHTS_DASHBOARD',
  ReportingEnabled = 'ENABLE_REPORTING',
  Concierge = 'MC_CONCIERGE',
  Bookings = 'BOOKINGS',
  PaidBookings = 'PAID_BOOKINGS',
  BookingsWithAccess = 'BOOKINGS_TIED_TO_ACCESS',
  ViewBookings = 'VIEW_BOOKINGS',
  BookingsTos = 'BOOKINGS_TOS',
  DoorSchedules = 'DOOR_SCHEDULES',
  PmsManagedUnitUpdate = 'PMS_MANAGED_UNIT_UPDATE',
  PmsManaged = 'PMS_MANAGED',
  PmsManagedV2 = 'PMS_MANAGED_V2',
  ServiceOrders = 'SERVICE_ORDER',
  DeliveryAssistant = 'DELIVERY_ASSISTANT',
  GeminiLatchPartner = 'GEMINI_LATCH_PARTNER',
  GeminiJunePartner = 'GEMINI_JUNE_PARTNER',
  GeminiHelloLandingPartner = 'GEMINI_HELLO_LANDING_PARTNER',
  GeminiHelloLandingDevPartner = 'GEMINI_HELLO_LANDING_DEV_PARTNER',
  GeminiCybersuitePartner = 'GEMINI_CYBERSUITE_PARTNER',
  GeminiBehringPartner = 'GEMINI_BEHRING_PARTNER',
  GeminiBLinePartner = 'GEMINI_B_LINE_PARTNER',
  GeminiOrionHausPartner = 'GEMINI_ORION_HAUS_PARTNER',
  GeminiJurnyPartner = 'GEMINI_JURNY_PARTNER',
  GeminiElevatedLivingPartner = 'GEMINI_ELEVATED_LIVING_PARTNER',
  GeminiAirClutterPartner = 'GEMINI_AIR_CLUTTER_PARTNER',
  GeminiTour24Partner = 'GEMINI_TOUR24_PARTNER',
  GeminiPynwheelPartner = 'GEMINI_PYNWHEEL_PARTNER',
  GeminiLivlyPartner = 'GEMINI_LIVLY_PARTNER',
  GeminiCommunityBossPartner = 'GEMINI_COMMUNITY_BOSS_PARTNER',
  GeminiPrimoPartner = 'GEMINI_PRIMO_PARTNER',
  GeminiEntratamationPartner = 'GEMINI_ENTRATAMATION_PARTNER',
  GeminiAvalonBaySelfGuidedTourPartner = 'GEMINI_AVALON_BAY_SELF_GUIDED_TOUR_PARTNER',
  GeminiChurchillPartner = 'GEMINI_CHURCHILL_PARTNER',
  GeminiVacasaPartner = 'GEMINI_VACASA_PARTNER',
  GeminiSeamPartner = 'GEMINI_SEAM_PARTNER',
  GeminiSentralPartner = 'GEMINI_SENTRAL_PARTNER',
  GeminiPropkeyPartner = 'GEMINI_PROPKEY_PARTNER',
  GeminiSwiftPartner = 'GEMINI_SWIFT_PARTNER',
  GeminiRiseIOPartner = 'GEMINI_RISEIO_PARTNER',
  PackageManagement = 'PACKAGE_MANAGEMENT',
  Spaces = 'SPACES',
  Bundling = 'MC_BUNDLING',
  Activity = 'ACTIVITY',
  RestrictionTier1 = 'RESTRICTION_TIER_1',
}

export const fromJSON = (json: any) => Object.assign({
  timezone: 'America/New_York'
}, json);
