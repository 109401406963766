<latch-subnav headerTitle="Support"></latch-subnav>
<div class="field-list latch-viewport">
  <div class="cell">
    <div class="cell-text-1">Latch support is available 24 hours a day.</div>
  </div>
  <div class="cell">
    <div class="cell-heading">Website</div>
    <div class="cell-body"><a href="https://support.latch.com" target="_blank">support.latch.com</a></div>
  </div>
  <div class="cell">
    <div class="cell-heading">Email</div>
    <div class="cell-body"><a href="mailto:support@latch.com">support@latch.com</a></div>
  </div>
  <div class="cell">
    <div class="cell-heading">Phone</div>
    <div class="cell-body">(888) 808-0670</div>
  </div>
  <div class="cell">
    <div class="cell-heading">Terms of Service</div>
    <div class="cell-body"><a href="https://www.latch.com/terms-of-service">Terms of Service</a></div>
  </div>
  <div class="cell">
    <div class="cell-heading">Privacy Notice</div>
    <div class="cell-body"><a href="https://www.latch.com/privacy-policy">Privacy Notice</a></div>
  </div>
</div>
