import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  faCaretDown, faCaretUp, faCheck, faChevronDown,
  faChevronLeft,
  faChevronRight, faMinus, faSearch, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { LatchIconService } from '@latch/latch-web';
import { CurrencyPipe } from 'manager/pipes/currency.pipe';
import { MomentDatePipe } from 'manager/pipes/moment-date.pipe';
import { PhoneFormattingPipe } from 'manager/pipes/phone-formatting.pipe';
import { PhoneNumberPipe } from 'manager/pipes/phone-number.pipe';
import { RelativeTimePipe } from 'manager/pipes/relative-time.pipe';
import { SharedModule } from 'shared/shared.module';
import { IntercomDisplayNamePipe } from '../pipes/intercom-display-name.pipe';
import { SelectUnitComponent } from './units/select-unit/select-unit.component';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { AgreementModalComponent } from './agreement-modal/agreement-modal.component';
import { InsightLineChartDataPipe } from './insights/insight-line-chart-data.pipe';
import { InsightPieChartDataPipe } from './insights/insight-pie-chart-data.pipe';
import { InsightComparisonComponent } from './insights/insight-comparison/insight-comparison.component';
import { ListFilterPipe } from 'manager/pipes/list-filter.pipe';
import { FilterStringListPipe } from 'manager/pipes/filter-string-list.pipe';
import { DuplicateEmailsComponent } from './duplicate-emails/duplicate-emails.component';
import { ProgressComponent } from './progress/progress.component';
import { KeycodeFormatPipe } from '../pipes/membership-codes.pipe';
import { InfiniteScrollComponent } from './list/infinite-scroll.component';
import { NoBuildingComponent } from './no-building/no-building.component';
import { StepIndicatorComponent } from './step-indicator/step-indicator.component';
import { FullNamePipe } from '../pipes/full-name.pipe';
import { ComplexSchedulePickerComponent } from './complex-schedule-picker/complex-schedule-picker.component';
import { TagComponent } from './frame/tag/tag.component';
import { AvatarComponent } from './frame/avatar/avatar.component';
import { AvatarPresentationComponent } from './frame/avatar-presentation/avatar-presentation.component';
import { FeedbackComponent } from './frame/feedback/feedback.component';
import { FileUploadListComponent } from './frame/file-upload-list/file-upload-list.component';
import { NotificationDialogComponent } from '@managerweb/components/notification-dialog/notification-dialog.component';

/**
 * Manager Web specific Shared Module
 * Used to declare and export shared components/directives that will be used
 * in feature modules.
 * @note Re-exports SharedModule, so don't use both ManagerSharedModule
 * and SharedModule, only use ManagerSharedModule.
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
    SharedModule,
    SelectUnitComponent,
    PhoneNumberPipe,
    PhoneFormattingPipe,
    IntercomDisplayNamePipe,
    RelativeTimePipe,
    CurrencyPipe,
    PhoneInputComponent,
    MomentDatePipe,
    AgreementModalComponent,
    InsightLineChartDataPipe,
    InsightPieChartDataPipe,
    InsightComparisonComponent,
    ListFilterPipe,
    FilterStringListPipe,
    ProgressComponent,
    KeycodeFormatPipe,
    InfiniteScrollComponent,
    StepIndicatorComponent,
    FullNamePipe,
    ComplexSchedulePickerComponent,
    TagComponent,
    AvatarComponent,
    AvatarPresentationComponent,
    FileUploadListComponent,
    FeedbackComponent,
  ],
  declarations: [
    SelectUnitComponent,
    PhoneNumberPipe,
    PhoneFormattingPipe,
    IntercomDisplayNamePipe,
    RelativeTimePipe,
    CurrencyPipe,
    PhoneInputComponent,
    MomentDatePipe,
    AgreementModalComponent,
    InsightLineChartDataPipe,
    InsightPieChartDataPipe,
    InsightComparisonComponent,
    ListFilterPipe,
    FilterStringListPipe,
    DuplicateEmailsComponent,
    ProgressComponent,
    KeycodeFormatPipe,
    InfiniteScrollComponent,
    NoBuildingComponent,
    StepIndicatorComponent,
    FullNamePipe,
    ComplexSchedulePickerComponent,
    TagComponent,
    AvatarComponent,
    AvatarPresentationComponent,
    FeedbackComponent,
    FileUploadListComponent,
    NotificationDialogComponent
  ],
  providers: [],
})
export class ManagerSharedModule {
  constructor(icon: LatchIconService) {
    icon.library.addIcons(faCaretDown);
    icon.library.addIcons(faCaretUp);
    icon.library.addIcons(faMinus);
    icon.library.addIcons(faCheck);
    icon.library.addIcons(faTimes);
    icon.library.addIcons(faSearch);
    icon.library.addIcons(faChevronDown);
    icon.library.addIcons(faChevronRight);
    icon.library.addIcons(faChevronLeft);
  }
}
