import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AutocompleteFilterItem, FilterItem, isDefined, LatchAnalyticsConstants, LatchAnalyticsService,
  LatchDatasource, LatchNavAction, LatchNavbarStateService
} from '@latch/latch-web';
import { Person } from 'manager/models/contact-cards';
import { ALL_ACCESS_STATES } from 'manager/models/key-membership';
import { PageWithTabsService } from 'manager/services/appstate/page-with-tabs.service';
import { FeatureService } from 'manager/services/appstate/feature.service';
import { ContactCardService, Sort } from 'manager/services/contact-card/contact-card.service';
import { BehaviorSubject, combineLatest, EMPTY, from, merge, Observable, of, Subject, Subscription } from 'rxjs';
import {
  catchError, concatMap, debounceTime, distinctUntilChanged,
  filter, finalize, map, shareReplay, skip, switchMap, take, takeUntil, tap, toArray
} from 'rxjs/operators';
import { NO_FILTER_RESULTS } from 'shared/common/constants';
import { startWithTap } from 'shared/utility/operators';
import { AccessLog, HelpfulVote, ImageSource, Photo, UnlockMethod, UnlockResult } from '../../../models/access-log';
import { Lock, LockDeviceType } from '../../../models/lock';
import { AccessLogService } from '../../../services/access-log/access-log.service';
import { ErrorHandlerService } from '../../../services/appstate/error-handler.service';
import { SelectedBuildingsService } from '../../../services/appstate/selected-buildings.service';
import { NextPage, PagedResponse } from '../../../services/interfaces';
import { LockService } from '../../../services/lock/lock.service';
import { alphabetizeBy, arrayOf, keyBy, omitEmptyNullOrUndefined } from 'manager/services/utility/utility';
import { getDefaultStartTime } from '../utility/activity-utility';
import { GeminiPartner } from 'manager/models/gemini-partner';

interface NavbarFilter {
  selectedPeople?: Person[];
  selectedLocks?: Lock[];
  startTime?: Date;
}

interface PaginatedPhotos {
  totalPages: number,
  previousPage: number | null,
  currentPage: number,
  nextPage: number | null,
  pageSize: number,
  totalElements: number,
  items: Photo[];
}

interface ShowingPhoto extends Photo {
  page: number,
  index: number,
}

enum KeyCode {
  Escape = 27,
  ArrowLeft = 37,
  ArrowUp = 38,
  ArrowRight = 39,
  ArrowDown = 40
}
const DEBOUNCE_TIME = 250;
const PEOPLE_START_DEFAULT = 0;
const PEOPLE_LIMIT_DEFAULT = 100;
const INITIAL_PEOPLE_NEXT_PAGE: NextPage = {
  start: PEOPLE_START_DEFAULT,
  limit: PEOPLE_LIMIT_DEFAULT,
};
const LOGS_START_DEFAULT = 0;
const LOGS_LIMIT_DEFAULT = 20;
const INITIAL_LOGS_NEXT_PAGE: NextPage = {
  start: LOGS_START_DEFAULT,
  limit: LOGS_LIMIT_DEFAULT,
};
const IMAGE_BURST_PAGE_SIZE = 10;

@Component({
  selector: 'latch-doors-activity-list-page',
  templateUrl: './doors-activity-list-page.component.html',
  styleUrls: ['./doors-activity-list-page.component.scss']
})
export class DoorsActivityListPageComponent implements OnInit, OnDestroy {

  private selectedBuildingUUIDs: string[] = [];

  public hasSmartHomeFeature$: Observable<boolean> = this.featureService.hasSmartHomeFeature$;
  public hasVisualAccessLogsFeature = false;
  public hasDeliveryAssistantFeature = false;

  public people: Person[] = [];
  private people$!: Observable<Person[]>;
  private peopleNextPage: NextPage | undefined = INITIAL_PEOPLE_NEXT_PAGE;
  private peopleScrolledToEnd = new Subject();

  // Map user uuid to the Person for that user.
  // Used to determine whether we can link to that person detail page (if we have
  // permission to get the contact card, we can show the person detail page) and
  // to get the name of hosts (the name of the actual guest is on the access log
  // so we only need this for HOST names).
  private userUUIDPersonMap = new Map<string, Person>();

  locksByUUID: { [lockUUID: string]: Lock; } = {};

  // List of all logs that meet the current filter criteria.
  accessLogs: AccessLog[] = [];

  // People and locks currently included in the filter - note that this is NOT updated while the user
  // is editing the list; that is maintained down in ActivityFilterComponent.
  public selectedPeople: Person[] = [];
  public selectedLocks: Lock[] = [];

  // The currently selected log, or undefined if none is selected.
  selectedLog: AccessLog | undefined;

  public showingPhoto: ShowingPhoto | undefined;

  public photosData: PaginatedPhotos | undefined;

  public isLoadingPeople = false;
  public isLoadingLocks = false;
  public isLoadingLogs = false;
  public isLoadingLogDetail = false;
  public ImageSource = ImageSource;

  startTime: Date | undefined;

  // Currently access logs may only be sorted by timestamp, so we only need a single
  // flag for ascending/descending
  sortAscending = false;

  public logsNextPage: NextPage | undefined = INITIAL_LOGS_NEXT_PAGE;

  // Keep a reference to any loadNext subscription so we can interrupt it when needed.
  loadNextSubscription: Subscription | undefined;

  // Width of the image - this will be the display height of the image, since we will transform it and rotate it 90
  // degrees. Can't be sized via CSS because we need to set the width of the image to be what will ultimately be its
  // display height.
  imageWidth = '';
  // Width of the image container - keeping this tight around the image allows us to keep the metadata info box below
  // this container matching the width of the image.
  imageContainerWidth = '';

  actions: LatchNavAction[] = [
    {
      id: 'export',
      name: 'Export',
      clickHandler: () => this.exportDoors()
    }
  ];

  // logImageContainer is the div that is sized how we would like the image to be sized - we use this ElementRef to
  // read its height in order to set the actual image's width.
  @ViewChild('logImageContainer') logImageContainer: ElementRef | undefined;

  public datasource = new LatchDatasource<AccessLog>({
    sort: { active: 'time', direction: 'desc' },
  });

  public searchPersonChange = new BehaviorSubject<string>('');

  private peopleFilterItem: AutocompleteFilterItem | undefined;

  private locksFilterItem: AutocompleteFilterItem | undefined;

  private navbarFilterValue: NavbarFilter = {
    selectedLocks: [],
    selectedPeople: [],
  };

  private navbarFilterStateInitialized = false;

  private unsubscribe$ = new Subject<void>();

  HelpfulVote = HelpfulVote;

  public geminiPartners: GeminiPartner[] = [];
  public hasOpenkitMultiPartnerFeature = false;

  get isEmpty() {
    return !this.isLoading && this.accessLogs.length === 0;
  }

  get noMoreLogs() {
    return this.accessLogs.length && !this.logsNextPage;
  }

  get isLoading() {
    return this.isLoadingPeople || this.isLoadingLocks || this.isLoadingLogs;
  }

  public get emptyListMessage(): string {
    const filterParams = this.navbarFilterValue;
    const emptyFilters: boolean = filterParams.selectedLocks?.length === 0
      && filterParams.selectedPeople?.length === 0
      && !filterParams.startTime;

    return emptyFilters ? 'There are no logs to view.' : NO_FILTER_RESULTS;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (!this.selectedLog) {
      return;
    }

    // IE11 (and some other browsers) populate event.which instead of event.keyCode.
    const keyCode = event.keyCode === undefined ? event.which : event.keyCode;

    switch (keyCode) {
      case KeyCode.Escape:
        // Close the access log detail view.
        this.selectedLog = undefined;
        break;
      case KeyCode.ArrowLeft:
        event.preventDefault();
        if (this.hasVisualAccessLogsFeature && this.photosData && this.showingPhoto) {
          this.checkImagesCurrentPage(this.showingPhoto.page);
          if (this.showingPhoto.index > 0) {
            this.setShowingImage(this.showingPhoto.index - 1);
          } else if (this.photosData.previousPage) {
            this.setImagesData(this.photosData.previousPage);
            this.setShowingImage(this.photosData.items.length - 1);
          }
        }
        break;
      case KeyCode.ArrowUp:
        event.preventDefault();
        this.selectPrevious();
        break;
      case KeyCode.ArrowRight:
        event.preventDefault();
        if (this.hasVisualAccessLogsFeature && this.photosData && this.showingPhoto) {
          this.checkImagesCurrentPage(this.showingPhoto.page);
          if (this.showingPhoto.index < (this.photosData.items.length - 1)) {
            this.setShowingImage(this.showingPhoto.index + 1);
          } else if (this.photosData.nextPage) {
            this.setImagesData(this.photosData.nextPage);
            this.setShowingImage(0);
          }
        }
        break;
      case KeyCode.ArrowDown:
        event.preventDefault();
        this.selectNext();
        break;
    }
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private selectedBuildingsService: SelectedBuildingsService,
    private accessLogService: AccessLogService,
    private contactCardService: ContactCardService,
    private lockService: LockService,
    private errorHandlerService: ErrorHandlerService,
    private analyticsService: LatchAnalyticsService,
    private featureService: FeatureService,
    private navbarStateService: LatchNavbarStateService,
    private pageWithTabsService: PageWithTabsService
  ) { }

  getLockName(lockUUID: string) {
    if (!lockUUID || !this.locksByUUID[lockUUID]) {
      return 'Unknown door';
    }
    return this.locksByUUID[lockUUID].name;
  }

  public getAccessLogName(accessLog: AccessLog): string {
    if (accessLog.method === UnlockMethod.MKO) {
      return 'Mechanical Key';
    }

    switch (accessLog.result) {
      case UnlockResult.Success:
      case UnlockResult.GuestSuccess:
        return accessLog.userFullName ?? '';
      case UnlockResult.Incorrect:
      case UnlockResult.NfcFailure:
        return 'Failed Attempt';
      case UnlockResult.DeadboltApplied:
        return 'Deadbolt Engaged';
      case UnlockResult.OutsideQualifiedAccess:
        return 'Out of Schedule';
    }

    return 'Failed Attempt';
  }

  getMethodText(accessLog: AccessLog) {
    switch (accessLog.method) {
      case UnlockMethod.Passcode:
        return this.isSuccessful(accessLog) ? 'Doorcode' : 'Invalid doorcode';
      case UnlockMethod.BLE:
        return this.isSuccessful(accessLog) ? 'App' : 'Unauthorized App';
      case UnlockMethod.NFC:
        return this.isSuccessful(accessLog) ? 'Keycard' : 'Invalid Keycard';
      case UnlockMethod.MKO:
        return 'Mechanical Key';
      default:
        return '';
    }
  }

  public canLinkToPersonDetail(userUUID: string | undefined): boolean {
    return isDefined(userUUID) && this.userUUIDPersonMap.has(userUUID);
  }

  public canLinkToLockDetail(lockUUID: string | undefined): boolean {
    return isDefined(lockUUID) && !!this.locksByUUID[lockUUID];
  }

  public getPersonName(userUUID: string): string {
    const person = this.userUUIDPersonMap.get(userUUID);
    return `${person?.userFirstName ?? ''} ${person?.userLastName ?? ''}`.trim();
  }

  isSuccessful(accessLog: AccessLog): boolean {
    return (
      accessLog.result === UnlockResult.Success ||
      accessLog.result === UnlockResult.GuestSuccess
    );
  }

  getMissingPhotoText(accessLog: AccessLog): string {
    const lock = this.locksByUUID[accessLog.lockUUID];
    if (lock && lock.device?.type === LockDeviceType.G) {
      return 'This device does not take photos.';
    }

    if (accessLog.method === UnlockMethod.NFC) {
      return 'Keycard photos are not currently supported.';
    }

    if (accessLog.method === UnlockMethod.MKO) {
      return 'Key photos are not currently supported.';
    }

    // Todo: backend doesn't currently handle this state with a specific flag, but empty string
    // means there should be a photo but it hasn't been received (yet).
    if (accessLog.imageURL === '') {
      return 'Photo not available yet. Try updating again.';
    }

    // Todo: spec says to show this copy if the door is private *and* it's the person's residence.
    // Current implementation for that would be a hack (since we don't know a person's residence for
    // sure). For now, assume that no photo + private lock means this is why there's no photo.
    if (lock && lock.isPrivate()) {
      return 'Photos are only taken of guests or failed attempts at a private door.';
    }

    return 'No photo available.';
  }

  ngOnInit() {
    this.analyticsService.track(LatchAnalyticsConstants.ViewPage, {
      [LatchAnalyticsConstants.PageName]: 'Activity'
    });

    this.featureService.hasVisualAccessLogsFeature$.pipe(
      take(1),
      takeUntil(this.unsubscribe$)
    ).subscribe(hasVisualAccessLogsFeature => this.hasVisualAccessLogsFeature = hasVisualAccessLogsFeature);

    this.featureService.hasDeliveryAssistantFeature$.pipe(
      take(1),
      takeUntil(this.unsubscribe$)
    ).subscribe(hasDeliveryAssistantFeature => this.hasDeliveryAssistantFeature = hasDeliveryAssistantFeature);

    this.featureService.hasOpenkitMultiPartnerFeature$.pipe(
      take(1),
      takeUntil(this.unsubscribe$)
    ).subscribe(hasOpenkitMultiPartnerFeature => this.hasOpenkitMultiPartnerFeature = hasOpenkitMultiPartnerFeature);

    this.selectedBuildingsService.getSelectedBuildings().pipe(
      map((buildings) => buildings.map((building) => building.uuid)),
      tap((buildingUUIDs) => this.selectedBuildingUUIDs = buildingUUIDs),
      takeUntil(this.unsubscribe$),
    ).subscribe((buildingUUIDs) => this.handlePageLoad(buildingUUIDs));

    this.datasource.sortChange().pipe(
      skip(1),
      takeUntil(this.unsubscribe$)
    ).subscribe((sort) => {
      if (sort.active === 'time') {
        const sortAscending = sort.direction === 'asc';
        this.handleSortWhen(sortAscending);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private handlePageLoad(buildingUUIDs: string[]): void {
    this.initializeFilters();
    this.initializeFiltersSubscription();

    this.isLoadingLocks = true;
    this.datasource.startLoading();
    const locksByUUID$ = this.lockService
      .getAllLocks(buildingUUIDs).pipe(
        map((locks) => locks.filter((lock) => lock.isInstalled)),
        map((allLocks) => alphabetizeBy(allLocks, 'name')),
        tap((allLocks) => this.updateLocksFilterItemData(allLocks)),
        map((allLocks) => keyBy(allLocks, 'uuid')),
        tap((locksByUUID) => this.locksByUUID = locksByUUID),
        tap(() => this.isLoadingLocks = false)
      );

    const geminiPartners$ = this.featureService.getGeminiPartners$.pipe(take(1));

    this.startPeopleSubscription();

    this.activatedRoute.queryParams.pipe(map((params) => ({
      selectedUserUUIDs: arrayOf(params.user),
      selectedLocks: arrayOf(params.lock),
      startTime: params.startTime ? new Date(params.startTime) : undefined
    }))).pipe(results => combineLatest([results, locksByUUID$, geminiPartners$]).pipe(
      map(([filterParams, locksByUUID, geminiPartners]) => ({
        ...filterParams,
        selectedLocks: filterParams.selectedLocks.map((lockUUID) => locksByUUID[lockUUID]),
        geminiPartners,
      }))),
      concatMap(filterParams => this.loadSelectedPeople(filterParams.selectedUserUUIDs).pipe(
        map(selectedPeople => ({ ...filterParams, selectedPeople })),
      )),
      tap(({ selectedPeople, selectedLocks, startTime, geminiPartners }) => {
        this.selectedPeople = selectedPeople;
        this.selectedLocks = selectedLocks;
        this.geminiPartners = geminiPartners;
        this.clearLogs();
        this.analyticsService.track('Activity Filter Changed', {
          'Num Users': selectedPeople.length,
          'Num Locks': selectedLocks.length,
          'Date selected?': startTime ? true : false
        });
      }),
      // Perform sorting business logic if start time filter has changed.
      switchMap(({ startTime }) => {
        const startTimeHasChanged = this.startTime !== startTime;
        const isAscending = !!startTime;
        this.startTime = startTime;
        if (!this.navbarFilterStateInitialized) {
          this.navbarFilterStateInitialized = true;
          this.navbarStateService.patchFormValue({
            selectedPeople: this.selectedPeople,
            selectedLocks: this.selectedLocks,
            startTime: this.startTime ?? getDefaultStartTime(),
          });
        }
        if (startTimeHasChanged) {
          // If a startTime has been set: show oldest first (ascending).
          // Otherwise we're showing all time: show most recent first (descending).
          this.datasource.setSort({ active: 'time', direction: isAscending ? 'asc' : 'desc' });
          // Close this chain and defer loading next to our sort change handler (handleSortWhen).
          return EMPTY;
        } else {
          return of({ startTime });
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(() => this.loadNext());
  }

  getPartnerName(partnerUUID: string): string {
    return this.geminiPartners.find(p => p.value === partnerUUID)?.name ?? '';
  }

  loadNext() {
    if (!this.logsNextPage) {
      return;
    }

    // We track each request we send for logs so that we can understand how often and far users
    // typically scroll through logs (rather than refining date filter).
    this.analyticsService.track('Activity Load Logs', {
      'Start Index': this.logsNextPage.start
    });

    // Loading the next batch may always cancel any in-flight requests. Ways to trigger loadNext:
    // - Change filters -> always clear state and fetch anew
    // - Sort by time -> always clear state and fetch anew
    // - Infinite scroll -> may only ever issue one request at a time
    if (this.loadNextSubscription) {
      this.loadNextSubscription.unsubscribe();
    }

    this.isLoadingLogs = true;
    this.datasource.startLoading();
    this.loadNextSubscription = this.accessLogService
      .getAccessLogs({
        buildingUUIDs: this.selectedBuildingUUIDs,
        guestUUIDs: this.selectedPeople.map((person) => person.userUUID),
        lockUUIDs: this.selectedLocks.map((lock) => lock.uuid),
        startTime: this.startTime,
        sortAscending: this.sortAscending,
        start: this.logsNextPage.start,
        limit: this.logsNextPage.limit
      })
      // Catch errors here so the user can still change the lock selection
      // (they might not have permission to view an individual lock's logs).
      .pipe(catchError((error) => {
        this.isLoadingLogs = false;
        this.datasource.stopLoading();
        this.clearLogs();
        this.errorHandlerService.handleException(error);
        return EMPTY;
      }))
      .subscribe(({ data, nextPage }: PagedResponse<AccessLog>) => {
        this.isLoadingLogs = false;
        this.datasource.stopLoading();
        this.updateLogs(data, nextPage);
      });
  }

  public onPeopleScrolledToEnd(value: boolean) {
    this.peopleScrolledToEnd.next(value);
  }

  public handleSearchPersonChange(searchPerson: string) {
    this.searchPersonChange.next(searchPerson);
  }

  private initializeFilters(): void {
    const filterItems: FilterItem[] = [];

    this.peopleFilterItem = {
      type: 'autocomplete',
      data: [],
      field: 'selectedPeople',
      label: 'People',
      initialValue: [],
      termChange: (term) => this.searchPersonChange.next(term),
      placeholder: 'Search People',
    };
    filterItems.push(this.peopleFilterItem);

    this.locksFilterItem = {
      type: 'autocomplete',
      data: [],
      field: 'selectedLocks',
      label: 'Doors',
      initialValue: [],
      placeholder: 'Search Doors',
    };
    filterItems.push(this.locksFilterItem);

    filterItems.push({
      type: 'date-time',
      field: 'startTime',
      label: 'Since',
      placeholder: 'Since',
    });

    this.navbarStateService.initializeFilter(filterItems);
    this.pageWithTabsService.setSubnavActions(this.actions);
  }

  private initializeFiltersSubscription(): void {
    this.navbarStateService.getFilterValueChange().pipe(
      skip(1),
      takeUntil(this.unsubscribe$)
    ).subscribe((value) => {
      this.navbarFilterValue = value;
      this.filter();
    });
  }

  private updateLocksFilterItemData(locks: Lock[]): void {
    if (this.locksFilterItem) {
      this.locksFilterItem.data = locks.map((lock) => ({
        name: lock.name,
        value: lock
      }));
    }
  }

  private updatePeopleFilterItemData(people: Person[]): void {
    if (this.peopleFilterItem) {
      this.peopleFilterItem.data = people.map(person => ({
        name: `${person.userFirstName} ${person.userLastName ?? ''}`,
        value: person,
      }));
    }
  }

  private startPeopleSubscription() {
    const loadPeople$ = this.searchPersonChange.pipe(
      debounceTime(DEBOUNCE_TIME),
      distinctUntilChanged(),
      tap(() => this.peopleNextPage = INITIAL_PEOPLE_NEXT_PAGE),
      switchMap(() => this.getPeople()),
      tap(people => this.people = people),
    );

    const loadMore$ = this.peopleScrolledToEnd.pipe(
      filter(() => !!this.peopleNextPage),
      switchMap(() => this.getPeople()),
      tap(nextPagePeople => this.people.push(...nextPagePeople))
    );

    this.people$ = merge(loadPeople$, loadMore$).pipe(
      map(() => this.people),
      tap(people => this.updateUserUUIDPersonMap(people)),
      shareReplay({ refCount: true, bufferSize: 1 }),
      takeUntil(this.unsubscribe$),
    );
    this.people$.subscribe((people) => {
      this.updatePeopleFilterItemData(people);
    });
  }

  private getPeople(): Observable<Person[]> {
    const buildingId = this.selectedBuildingUUIDs[0];
    const search = this.searchPersonChange.value;
    const nextPage = this.peopleNextPage;

    return this.contactCardService.getPeople(buildingId, {
      accessState: ALL_ACCESS_STATES,
      leaseState: [],
      sort: Sort.ALPHABETICAL,
      includeResidentsGuests: true,
      search,
      ...nextPage,
    }).pipe(
      startWithTap(() => this.isLoadingPeople = true),
      tap(response => this.peopleNextPage = response.metadata.nextPage),
      map(response => response.people),
      tap(() => this.isLoadingPeople = false),
    );
  }

  private loadSelectedPeople(selectedUserUUIDs: string[]): Observable<Person[]> {
    const toSelectedPeople = (people: Person[]) => {
      // concat selected and all people to look for selectedUserUUIDs
      people = people.concat(this.selectedPeople);
      const peopleByUserUUID = keyBy(people, 'userUUID');
      return from(selectedUserUUIDs).pipe(
        startWithTap(() => this.isLoadingPeople = true),
        concatMap(userUUID => {
          const person = peopleByUserUUID[userUUID];
          return person ? of(person) :
            this.getPersonForUser(userUUID);
        }),
        toArray(),
        tap(() => this.isLoadingPeople = false),
      );
    };

    return this.people$.pipe(
      take(1),
      switchMap(people => toSelectedPeople(people)),
      tap(selectedPeople => this.selectedPeople = selectedPeople),
    );
  }

  // When the filter is updated, we change the URL parameters.
  // This retriggers the selectedLocks$ observable above, where we fetch new access logs.
  private filter() {
    const selectedPeople = this.navbarFilterValue.selectedPeople ?? [];
    const selectedLocks = this.navbarFilterValue.selectedLocks ?? [];
    const startTime = this.navbarFilterValue.startTime;
    const queryParams = omitEmptyNullOrUndefined({
      user: selectedPeople.map(person => person.userUUID),
      lock: selectedLocks.map(lock => lock.uuid),
      startTime: startTime ? startTime.toISOString() : null
    });
    this.router.navigate(['/console/activity'], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  handleSortWhen(sortAscending: boolean) {
    this.clearLogs();
    this.sortAscending = sortAscending;
    this.loadNext();
  }

  handleSelectLog(accessLog: AccessLog) {
    this.setSelectedLog(accessLog);

    // When displaying log details, we need to know whether we have permission to view the person
    // detail page of userUUID and hostUUID, and we need to get the name of hostUUID. Fetch both
    // user's contact cards (if we don't have them already).
    const userUUIDsToLoad = [accessLog.userUUID, accessLog.hostUUID]
      // Either of these fields can be null, so filter those out.
      .filter((uuid): uuid is string => isDefined(uuid))
      // Only fetch if we haven't already.
      .filter(uuid => !this.userUUIDPersonMap.has(uuid));

    if (userUUIDsToLoad.length > 0) {
      from(userUUIDsToLoad).pipe(
        concatMap(userUUID => this.getPersonForUser(userUUID)),
        tap(person => this.updateUserUUIDPersonMap([person])),
        startWithTap(() => this.isLoadingLogDetail = true),
        finalize(() => this.isLoadingLogDetail = false),
        takeUntil(this.unsubscribe$),
      ).subscribe();
    }
  }

  private getPersonForUser(userUUID: string): Observable<Person> {
    const buildingUUID = this.selectedBuildingUUIDs[0];
    return this.contactCardService.getPersonForUser(buildingUUID, userUUID).pipe(
      catchError(() => EMPTY),
    );
  }

  private updateUserUUIDPersonMap(people: Person[]) {
    people.forEach(person => {
      this.userUUIDPersonMap.set(person.userUUID, person);
    });
  }

  selectPrevious() {
    // Go to the previous log detail view.
    const selectedIndex = this.accessLogs.findIndex(log => log.uuid === this.selectedLog?.uuid);
    // Don't step outside the bounds of this page.
    const newSelectedIndex = Math.max(0, selectedIndex - 1);
    this.setSelectedLog(this.accessLogs[newSelectedIndex]);
  }

  selectNext() {
    // Go to the next log detail view.
    const selectedIndex = this.accessLogs.findIndex(log => log.uuid === this.selectedLog?.uuid);
    // Don't step outside the bounds of this page.
    const newSelectedIndex = Math.min(selectedIndex + 1, this.accessLogs.length - 1);
    this.setSelectedLog(this.accessLogs[newSelectedIndex]);
  }

  setImagesData(page: number): void {
    const images = (this.selectedLog && this.selectedLog.photos) || [];
    this.photosData = this.getPaginatedImages(images, page, IMAGE_BURST_PAGE_SIZE);
  }

  setShowingImage(index: number | undefined): void {
    if (index !== undefined && this.photosData && this.photosData.items[index]) {
      this.showingPhoto = {
        url: this.photosData.items[index].url,
        imageSource: this.photosData.items[index].imageSource,
        page: this.photosData.currentPage,
        index
      };
    } else {
      this.showingPhoto = undefined;
    }
  }

  private checkImagesCurrentPage(page: number): void {
    if (this.photosData) {
      if (this.photosData.currentPage !== page && page > 0 && page <= this.photosData.totalPages) {
        this.setImagesData(page);
      }
    }
  }

  private setSelectedLog(accessLog: AccessLog): void {
    this.selectedLog = accessLog;
    this.checkImagesData();
    this.setShowingImage(this.photosData ? 0 : undefined);
  }

  private checkImagesData(): void {
    if (this.selectedLog && this.selectedLog.photos && this.selectedLog.photos.length > 0) {
      this.setImagesData(1);
    } else {
      this.photosData = undefined;
    }
  }

  private getPaginatedImages(photos: Photo[], page: number, pageSize: number): PaginatedPhotos {
    const startingSliceIndex: number = (page - 1) * pageSize;
    const totalPages: number = Math.ceil(photos.length / pageSize);
    return {
      totalPages,
      previousPage: (page > 1) ? page - 1 : null,
      currentPage: page,
      nextPage: (page < totalPages) ? page + 1 : null,
      pageSize,
      totalElements: photos.length,
      items: photos.slice(startingSliceIndex, startingSliceIndex + pageSize)
    };
  }

  toggleVote(state: HelpfulVote) {
    if (this.selectedLog?.visualAccessLogMetadata) {
      this.selectedLog.visualAccessLogMetadata.helpfulVote =
        this.selectedLog.visualAccessLogMetadata.helpfulVote === state ? HelpfulVote.None : state;
      this.accessLogService.updateVisualAccessLogMetadata(this.selectedLog, this.selectedLog.visualAccessLogMetadata).subscribe();
    }
  }

  private clearLogs() {
    this.accessLogs = [];
    this.datasource.set([]);
    this.logsNextPage = INITIAL_LOGS_NEXT_PAGE;
    this.updateSubnavSubtitle();
  }

  private updateLogs(accessLogs: AccessLog[], logsNextPage?: NextPage) {
    this.accessLogs = this.accessLogs.concat(accessLogs);
    this.datasource.append(accessLogs);
    this.logsNextPage = logsNextPage;
    this.updateSubnavSubtitle();
  }

  private updateSubnavSubtitle() {
    this.pageWithTabsService.setSubnavSubtitle(`(${this.accessLogs.length}${this.logsNextPage ? '+' : ''})`);
  }

  private exportDoors(): void {
    this.accessLogService.exportAccessLogs({
      buildingUUIDs: this.selectedBuildingUUIDs,
      guestUUIDs: this.selectedPeople.map((person) => person.userUUID),
      lockUUIDs: this.selectedLocks.map((lock) => lock.uuid),
      startTime: this.startTime,
      sortAscending: this.sortAscending,
    }).subscribe(data => {
      const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(data)}`;
      window.open(csvContent);
    });
  }
}
