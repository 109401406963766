import { RawAccountAdministrationSettings } from './account-administration';
import { LeaseStatus } from './pms-access';

/**
 * A User is a user of the system - someone who has been granted access to some lock at some point.
 *
 * It provides various metadata about that user, like name and contact information, as well as a list of that user's
 * accesses.
 *
 * It has many optional fields - this is to allow clients that want to populate a list of users to start with a
 * skeleton list of only the information provided by a service that does not provide metadata, and later to fill in
 * the rest of the structure. The optional fields might never be populated - that data might not exist, even if a
 * later endpoint call returns the User's metadata.
 *
 * Open question: do we want to separate out the idea of fields that are "always optional" and those that are optional
 * until additional data is returned? Will there be places that we will want a guarantee that a piece of data will be
 * retrieved eventually?
 */
export interface User {
  /** Required fields. */

  uuid: string;

  /** Optional fields that may be filled in later. */

  firstName?: string;
  lastName?: string;
  email?: string;
  mobileNumber?: string;
  unitId?: number;

  // The existence of signupDate indicates that the user has gone through some sort of onboarding
  // process. Currently this is just the Welcome Email sent when users are created through Manager
  // Web (opposed to guests added from the app who don't get this email), but the meaning could
  // broaden in the future (e.g. self-signup). See LMC-434 for more detail.
  signupDate?: Date;
  credential?: UserCredential;
  accountAdministrationPrivileges?: RawAccountAdministrationSettings[];

  partnerUUIDs?: string[];
  currentLoginDate?: Date
}

export interface UserCredential {
  accountCreated: Date;
  inviteSent: Date;
  consumerAppLoggedIn: boolean;
  keycard?: Keycard;
}

export interface Keycard {
  state: NFCState;
  serialNumber?: string;
}

export enum NFCState {
  None = 'NONE',
  Activated = 'ACTIVATED',
  ActivationConfirmed = 'ACTIVATION_CONFIRMED',
  ActivationSynced = 'ACTIVATION_SYNCED',
  Deactivated = 'DEACTIVATED',
  DeactivationConfirmed = 'DEACTIVATION_CONFIRMED'
}

/**
 * Text to display to user for each NFCState.
 */
export const NFCStateText = {
  // Sent out activation email but not yet clicked
  [NFCState.Activated]: 'Activation pending',
  // Sent out activation email, link clicked, not yet synced
  [NFCState.ActivationConfirmed]: 'Activation pending',
  // Sent out activation email, link clicked, synced
  [NFCState.ActivationSynced]: 'Activated',
  // Sent out deactivation email but not yet clicked
  [NFCState.Deactivated]: 'Deactivation pending',
  // Sent out deactivation email, link clicked, not yet synced
  [NFCState.DeactivationConfirmed]: 'Deactivation pending',
  // Never had keycard OR Sent out deactivation email, link clicked, synced
  [NFCState.None]: 'No Keycard',
};

export const NFCStateSupportingText = {
  // Sent out activation email but not yet clicked
  [NFCState.Activated]: 'Request sent to resident.',
  // Sent out activation email, link clicked, not yet synced
  [NFCState.ActivationConfirmed]: 'Update Doors with the Latch App to complete activation.',
  // Sent out activation email, link clicked, synced
  [NFCState.ActivationSynced]: '',
  // Sent out deactivation email but not yet clicked
  [NFCState.Deactivated]: 'Request sent to resident.',
  // Sent out deactivation email, link clicked, not yet synced
  [NFCState.DeactivationConfirmed]: 'Update Doors with the Latch App to complete deactivation.',
  // Never had keycard OR Sent out deactivation email, link clicked, synced
  [NFCState.None]: '',
};

export interface DuplicateResponse {
  rows: Duplicate[];
  numDuplicates: number;
}

export interface DedupingResponse {
  failedUUIDs: string[];
  successfulUUIDs: string[];
  failureDetails?: Record<string, DedupingResponseErrorType>;
}

export interface DuplicateEmail {
  email: string;
  firstName: string;
  lastName: string;
  leaseStatus: LeaseStatus;
}

export enum DedupingResponseErrorType {
  CONTACT_CARD_NOT_FOUND = 'CONTACT_CARD_NOT_FOUND',
  CONTACT_CARD_WITH_RESIDENT_INFORMATION_EXIST = 'CONTACT_CARD_WITH_RESIDENT_INFORMATION_EXIST',
  REQUIRE_DATA_MISSING = 'REQUIRE_DATA_MISSING',
  CONTACT_CARD_UPDATE = 'CONTACT_CARD_UPDATE',
  USER_UPDATE = 'USER_UPDATE',
  MULTIPLE_RESIDENT_WITH_SAME_EMAIL = 'MULTIPLE_RESIDENT_WITH_SAME_EMAIL',
  MULTIPLE_DUPLICATES_FOR_CONTACT_CARD_EXIST = 'MULTIPLE_DUPLICATES_FOR_CONTACT_CARD_EXIST',
  SERVICE_ERROR = 'SERVICE_ERROR',
  NOT_NAME_MISMATCH = 'NOT_NAME_MISMATCH',
  NOT_EMAIL_MISMATCH = 'NOT_EMAIL_MISMATCH',
}

export const DedupingResponseErrorMessages = new Map<DedupingResponseErrorType, string>([
  [
    DedupingResponseErrorType.CONTACT_CARD_NOT_FOUND,
    'Oops something went wrong, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.CONTACT_CARD_WITH_RESIDENT_INFORMATION_EXIST,
    'This PMS resident already exists in Latch, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.REQUIRE_DATA_MISSING,
    'Oops something went wrong, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.CONTACT_CARD_UPDATE,
    'Failed updating this Latch user, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.USER_UPDATE,
    'Failed updating this Latch user, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.MULTIPLE_RESIDENT_WITH_SAME_EMAIL,
    'Email address already exists in Latch, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.MULTIPLE_DUPLICATES_FOR_CONTACT_CARD_EXIST,
    'Can’t apply multiple updates to the same Latch user. Please pick only the one you wish to apply and retry.'
  ],
  [
    DedupingResponseErrorType.SERVICE_ERROR,
    'Oops something went wrong, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.NOT_NAME_MISMATCH,
    'Illegal operation, try refreshing the page. If that doesn’t help, please contact support.'
  ],
  [
    DedupingResponseErrorType.NOT_EMAIL_MISMATCH,
    'Illegal operation, try refreshing the page. If that doesn’t help, please contact support.'
  ],
]);

export interface Duplicate {
  duplicateUUID: string;
  pmsDuplicate: {
    fullName: string;
    email: string;
    phoneNumber: string;
  };
  latchDuplicate: {
    fullName: string;
    email: string;
    phoneNumber: string;
  };
  lastUpdated?: number;
}

export interface UserNameInfo {
  firstName?: string;
  lastName?: string;
}

export enum UserNotificationCategories {
  UserLeakNotifications = 'USER_LEAK_NOTIFICATIONS'
}

export enum UserNotificationTypes {
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export interface UserNotificationInfo {
  name: UserNotificationCategories;
  values: Record<UserNotificationTypes, boolean | undefined>;
}

export enum UserConsentKey {
  PayBeta = 'paybeta',
  DwollaToS = 'mgrdwollatos',
  StripeToS = 'mgrstripetos'
}

export enum UserConsentAction {
  Accept = 'accept',
  Revoke = 'revoke'
}

export enum UserConsentStatus {
  Unset = 'UNSET',
  Accepted = 'ACCEPTED',
  Revoked = 'REVOKED'
}
